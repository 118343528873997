.connect-btn{
    background-color: rgb(0, 0, 255) !important;
}

.wallet{

    color: transparent;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    background-image: linear-gradient(330.4deg, rgb(191, 68, 240) 4.54%, rgb(248, 114, 203) 59.2%, rgb(255, 153, 212) 148.85%);
    background-clip: text;
    background-size: 110%;

}

@media only screen and (max-width: 600px) {
    .ant-row{
        flex-direction: column;
        align-items: center;
    }
}

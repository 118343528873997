.shopping-cart {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px;
}

.shopping-cart-counter {
  padding: 2px;
  min-width: 14px;
  border-radius: 30%;
  background-color: red;
  font-size: 11px;
  text-align: center;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: initial;
}
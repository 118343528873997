.dapp-core-component__main__btn-primary.dapp-core-component__main__btn.connect-servive-btn {
    padding: unset !important;
    width: 100% !important;
    margin: unset !important;
    border: none !important;
    background-color: none !important;
}

.dapp-core-component__main__btn-primary:hover{
    background-color: none !important;
}

.dapp-core-component__main__btn-primary{
    background-color: none !important;
}

.connect-service {
    font-size: 20px;
}

.connect-service .ant-card-body {
    padding: 20px;
}

.connect-service .ant-card-body .connect-service-logo {
    background-position: center center;
    background-size: cover;
    width: 45px;
    height: 45px;
    margin-right: 10px;
}

.connect-service-logo img {
    width: 100%;
    height: 100%;
}

.connect-service .connect-service-title {
    width: unset;
}

.connect-service .row {
    justify-content: space-between;
}

@media all and (max-width: 362px) {
    .connect-service .ant-card-body .connect-service-logo {
        margin: auto;
    }

    .connect-service .connect-service-title {
        width: 100%;
    }

    .connect-service .row {
        align-items: center;
        flex-direction: column;
    }

}
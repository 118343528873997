.ant-layout-sider.sider {
    height: '100vh';
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 100px 16px 0 16px;
    background: transparent;
}
.layout-header {
    position: fixed;
    z-index: 10;
    width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: 90px auto;
    grid-template-areas: "logo header-content";
    align-items: center;
    background-color: none;
}

.layout-header .logo {
    grid-area: logo;
    height: 64px;
}

.header-content {
    display: grid;
    grid-area: header-content;
    grid-template-columns: auto;
    align-items: center;
    padding-right: 20px;
    margin-bottom : 120px;
}

.header-connect {
    display: none;
    line-height: 23px;
}

.menu-icon {
    display: none;
    grid-area: menu-icon;
    justify-self: end;
}

.logo{
    margin-bottom: 90px !important;
    vertical-align: initial;
    margin-right: 10px !important;
}
.menu-icon-content {
    display: flex;
    gap: 20px;
    margin-bottom : 80px;
}

.search-mobile {
    padding-top: 100px;
    padding-bottom: 90px;
}

.layout-content {
    padding: 20px;
    padding-left: 200px;
}
.img{
    vertical-align: initial;
}
.header-content .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
}

.header-content .ant-menu-vertical,
.sider .ant-menu-vertical {
    border-right: none;
    border-inline-end: none;
}

.header-content .ant-menu-item-selected a,
.sider .ant-menu-item-selected a {
    color: inherit;
}

.header-search {
    display: inline-block;
    gap: 20px;
    align-items: center;
    bottom: 70px;
}
.header-search-box {
    flex: 1;
}


@media all and (max-width: 768px) {
    .layout-header {
        grid-template-areas: "logo menu-icon""header-content header-content";
    }

    .header-content.desktop-search {
        display: none;
    }

    .header-content {
        grid-template-rows: auto;
        grid-template-columns: none;
        grid-row-gap: 20px;
        width: 100%;
        padding: 30px 10px;
        background: #001529;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top: 1px solid #adb5bd;
    }

    .header-connect {
        display: block;
        width: 100%;
    }

    .menu-icon {
        display: inline;
        margin-right: 20px;
    }

    .header-search,
    .sider {
        display: none;
    }
    .layout-content {
    padding-left: 20px;
    }
}

.cart-modal {
    padding: 10px 0;
}

.cart-modal-item {
    margin: 4px 0;
}
.home-layout {
    min-height: 100vh;
}

.home-layout .home-content {
    padding: 0px 20px;
}

.logo-img {
    width: 80%;
    max-width: 600px;
}

.home-layout .connect-typography {
    color: inherit;
    display: flex;
    margin-bottom: 14px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-width: 200px;
}

.home-layout .connect-typography p {
    margin-bottom: 0;
}

.home-layout .connect-typography p:first-child {
    margin-right: 8px;
}
.home-content{
    margin-top: 60px;
}

.header{
    color: transparent;
    font-size: 1.875rem;
    font-weight: 800;
    text-align: center;
    background-image: linear-gradient(330.4deg, rgb(68, 188, 240) 4.54%, rgb(114, 152, 248) 59.2%, rgb(160, 153, 255) 148.85%);
    background-clip: text;
    background-size: 110%;
    margin-bottom: 1px;
}

.details{
    color: rgba(247, 247, 247, 0.826);
    font-size:  1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
}

@media only screen and (min-device-width: 320px) and (max-device-height: 896px) and (orientation: landscape) {
    .home-layout {
        padding: 0 20px 30px 20px;
    }

    .home-layout .home-content {
        padding: 0px;
    }

    .logo-img {
        width: 50%;
    }
}

.connect-transparent .connect-btn {
    background: transparent;
    color: inherit;
}
.connect-transparent .select-btn.ant-select .ant-select-selector,
.connect-transparent .select-btn.ant-select .ant-select-arrow {
    background: transparent;
    color: #fff;
}
.updates-bar{
    color: #fff;
    padding: 10px;
    margin: 50px;
}

.input-popup{
    color: black;
    font-weight: bold;
}
.popup-heading{
    font-size: 30px;
    font-weight: bolder;
    color: transparent;
    background-image: linear-gradient(330.4deg, rgb(190, 37, 250) 4.54%, rgb(177, 114, 248) 59.2%, rgb(238, 153, 255) 148.85%);
    background-clip: text;
    background-size: 110%;
    padding-bottom: 30px;
    position: absolute;
    top: 50px;
  }

.popup-button{
    background-color: blue;
    margin-top: 20px;
}
.card .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #fff;
}

.card .ant-steps-item-process .ant-steps-item-icon {
    border: 1px solid rgba(0, 0, 0, .25);
}

.card .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
    color: rgba(0, 0, 0, .25);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: inherit
}

.registration-title {
    font-size: 24px;
}

.description {
    color: rgb(173, 187, 205);
}

.payment-select-form .ant-form-item-label>label {
    height: 100%;
}

.connect-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end         ;
}

@media all and (min-width: 1200px) {
    .card .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
        max-width: 350px;
    }
}

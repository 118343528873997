.search-field {
    height: inherit;
}

.search-field .ant-input,
.search-field .ant-btn {
    font-size: 25px;
}

.search-field .ant-input-affix-wrapper,
.search-field .ant-input-affix-wrapper:focus,
.search-field .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    outline: none;
    border: none;
    box-shadow: none;
}

.search-field .ant-btn {
    height: auto;
}

.search-icon {
    font-size: 20px;
    color: #adb5bd;
}
* {
  padding: 0;
  margin: 0;
}
html, body {
  margin: 0;
  padding: 0;
  background-color: black;
}

.swap-container{
text-align: center;
display: flex;
justify-content: center; /* Horizontally center content */
align-items: center;
}

.css-8oi10d{
  display:none !important;
}

.css-wzmf6s{
display: none !important;
}

.logo {
margin:10px;
display: inline-block;
}

.bold {
color: white;
font-weight: bold; /* Bold font weight */
font-size: 1.5rem;
padding: 5px;
}

.slim {
color: yellow;
font-weight: 300; /* Slim font weight */
font-size: 1.2rem;
}

.swap-container1{
color: white;
text-align: center;
display: flex;
justify-content: center; /* Horizontally center content */
 align-items: center;
 padding: 5px;
}
.boldinfo {
color: white;
font-weight: bold; /* Bold font weight */
font-size: 1.2rem;
padding: 5px;
}
.slim1 {
font-size: 1rem;
font-weight: 200;
margin-top: 1px;
}

.navbar {
color: #fff;
display: flex;
align-items: center;
padding: 3px 20px;
width: 100%;
}

.navbar-brand {
font-size: 1.5rem;
}

.navbar-nav {
list-style: none;
display: flex;
justify-content: flex-end; /* Align items to the right */
align-items: center;
margin:0;
padding:0;
margin-left: auto;
}

.nav-item {
margin-left: 10px;
}

.nav-link {
font-weight: bold;
font-size: large;
color: #ccc; /* Default color for unselected items */
text-decoration: none;
padding: 5px 10px;
}

.nav-link:hover,
.nav-link.active {
color:white; /* Bright color for selected item */
}
.nav-icon{
display: none;
}

.menu-toggle {
display: none;
cursor: pointer;
}
.navbar .nav-item a .anticon {
display: none !important;
}

@media screen and (min-width: 332px) and (max-width: 600px) {
.menu-toggle {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  top: 10px;
  right: 10px;
  z-index: 1000;
  font-size: 24px;
}
.navbar {
  padding: 3px 2px; /* Reduce padding for small screens */
  height: 5%;
  width: 100%;
  margin: 0 auto;
}

.nav-link {
  font-weight: bold;
  color: white;
  text-decoration: none;
  padding: 2px 3px;
  backdrop-filter: blur(10px);
}
.nav-icon {
  display: flex;
  align-items: center;
  position: relative;
}
.navbar-nav {
  display: none;
  position: absolute;
  top: 80px; /* Adjust this value according to your design */
  left: 0;
  width: 100%;
  background-color: black; /* Background color for the menu */
  padding: 10px 0;
  box-shadow: 0 2px 5px rgba(14, 1, 24, 0.1);
  z-index: 999;
}

.navbar .nav-item a .anticon {
  display: inline !important;
}

.navbar-nav.open {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item {
  margin: 10px 0;
}

.menu-toggle.open {
  color: white; /* Color of the close icon when menu is open */
}

.menu-toggle.open + .navbar-nav {
  display: flex;
}

.nav-link:hover::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(253, 62, 224); /* Change this to your desired color */
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color:  rgb(253, 62, 224); /* Change this to your desired color */
}


}
.App {
  text-align: center;
  color: #fff;
  display: flex;
 justify-content: center;
 align-items: center;
 height: 100vh;
}

.preloader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.preloader img {
  width: 100px; /* Adjust image size as needed */
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-out {
  animation: fade-in 0.4s ease-in-out infinite, fade-out 0.4s ease-in-out 0.8s infinite;
}

.navbar-right {

  flex-direction: column; /* Display items in a column */
  align-items: flex-start; /* Align items to the right */
  display: flex;
  
}

.navbar-nav1 {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgb(1 0 12/var(--tw-bg-opacity));
}

.nav-item1{
  margin-bottom: 10px; /* Adjust margin between items */
}

.nav-link1{
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
}

.nav-link1:hover {
  background-color: #555;
}

.scrolls{
  color: rgba(255, 255, 255, 0.848);
}

.wallet{
  color: transparent;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  background-image: linear-gradient(330.4deg, rgb(191, 68, 240) 4.54%, rgb(248, 114, 203) 59.2%, rgb(255, 153, 212) 148.85%);
  background-clip: text;
  background-size: 110%;
  margin-top: 1px;
}

.flip-bar {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 900;
  font-size: larger;
}

.icon-container {
  margin-right: 10px;
}

.speaker-icon {
  font-size: 20px;
}

.link1 {
  margin-left: 10px;
  font-weight: 700;
}

@media screen and (min-width: 332px) and (max-width: 768px) {
  .flip-bar {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 900;
    font-size: larger;
    margin-top: 120px;
  }
  div.text-container{
    display: flex;
    align-items: center;
  }
}

.username{
  justify-content: center;
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: large;
}


:where(.css-dev-only-do-not-override-wlqbnr).ant-input {
  background-color: white;
  color: #808080;
}

.verify-button{
  margin-right: 5px;
  margin-top: 15px;
  background-color: #9b60ff;
}

.verify-setup{
  margin-top: 15px;
  background-color: #9b60ff;

}

@media screen and (max-width: 600px) {

.button-flex{
  flex-direction: row;
}

}

.heading{
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: medium;
}

.update-setup{
  margin-top: 15px;
  background-color: #9b60ff;
  margin-left: 5px;
}


.name{
  justify-content: center;
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-size: large
}

.referral-section{
  margin-top: 40px;
}

.referral-heading{
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: large;
}

.copyicon{
  color: #fff;
  font-size: 30px;
}

.referralCount{
  margin-top: 50px;

}

.referralHeading{
  font-weight: bold;
  justify-content: center;
  color: #fff;
  font-size: medium;
}



.referralReward{
  font-weight: bold;
  justify-content: center;
  color: #fff;
  font-size: medium;
  margin-left: 10px;
}


.userName{
  font-weight: bolder;
  color:#fff;
  text-align: center;
  font-size: 25px;
  justify-content: center;
}

.Data{
  font-weight: 500;
  color:#fff;
 margin-top: 25px;
 font-size: large;
}
.userName1{
  margin-right: 50px;
}